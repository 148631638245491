<template>
  <Form class="auth-form" :action="onSignin" :payload="{}">
    <header>
      <Icon class="logo" name="brand-picsellia" />
      <h1>Login with</h1>
    </header>

    <TextInput class="input" v-model="username" label="Username" autocomplete="username" />
    <TextInput class="input" v-model="password" label="Password" type="password" autocomplete="current-password" />
    <Turnstile ref="turnstile" :site-key="siteKey" v-model="token" />
    <div v-if="error" class="error">{{ error }}</div>

    <template #footer="{ loading }">
      <Button type="submit" variant="contained" color="primary" :loading="loading || done" :disabled="disabled || done">
        Sign In
      </Button>
      <p class="demo-title">
        <span>Don't have an account yet?&nbsp;</span>
        <a href="https://www.picsellia.com/contact" class="demo-title">Request Demo</a>
      </p>
      <a href="/forgot-password" class="forget-password-title">Forgot password?</a>
    </template>
  </Form>
</template>

<script>
import Icon from "@/components/icons/Icon";
import Form from "@/components/Form/Form.vue";
import Button from "@/components/buttons/Generic.vue";
import TextInput from "@/components/inputs/Text.vue";
import AuthApi from "@/js/api/AuthApi.js";
import Turnstile from "@/components/auth/Turnstile.vue";

const page = {
  name: "SigninOverviewPage",
  components: { Turnstile, TextInput, Button, Form, Icon },
  data() {
    return {
      loading: false,
      username: "",
      password: "",
      error: null,
      disabled: true,
      done: false,
      siteKey: process.env.TURNSTILE_SITE_KEY,
      token: "",
    };
  },
  watch: {
    token: {
      handler() {
        this.disabled = !this.token;
      },
    },
  },
  methods: {
    async onSignin() {
      if (this.loading) return;
      this.loading = true;
      this.error = null;
      try {
        await AuthApi.login({ username: this.username, password: this.password, captcha_token: this.token });
        this.done = true;
        location.reload();
      } catch (error) {
        console.error(error);
        if (error.status === 401) {
          this.error = "Invalid username or password";
        } else {
          this.error = error.body.detail[0];
        }
        this.$refs.turnstile.reset();
        this.password = "";
      } finally {
        this.loading = false;
      }
    },
  },
};

window.pageComponent = page;
export default page;
</script>

<style lang="scss">
@import "@/styles/authPage.scss";

.demo-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  font-weight: 700 !important;
}

.forget-password-title {
  text-align: center;
}
</style>
