<template>
  <label>
    <slot name="label">
      <span v-if="label">
        {{ label }}
        <span v-if="required" class="required">*</span>
      </span>
    </slot>
    <span class="input-wrapper" :class="{ invalid: error }">
      <input
        :type="showPassword ? 'text' : type"
        ref="input"
        v-model="value"
        @input="$emit('input')"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @keydown.stop="$emit('keydown', $event)"
        @keyup.stop="$emit('keyup', $event)"
        @keyup.enter.prevent="$emit('enter')"
        @keyup.esc.prevent="$emit('esc')"
        @keydown.delete="$emit('delete')"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :autocomplete="autocomplete"
      />
      <icon
        v-if="type === 'password'"
        class="password-visibility"
        @click="showPassword = !showPassword"
        :name="showPassword ? 'eye' : 'eye-closed'"
      />
    </span>
    <slot name="error" v-if="error">
      <span class="error">{{ error }}</span>
    </slot>
  </label>
</template>

<script>
import Icon from "@/components/icons/Icon";

export default {
  name: "TextInput",
  components: { Icon },
  props: {
    modelValue: { type: String, required: true },
    label: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    error: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    type: { type: String, default: "text" },
    autocomplete: { type: String, default: "off" },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  emits: ["update:modelValue", "input", "enter", "delete", "esc", "blur", "focus", "keydown", "keyup"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    blur() {
      this.$refs.input.blur();
    },
  },
};
</script>
<style scoped lang="scss">
label {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin: 0;
  color: var(--label);

  .required,
  .error {
    color: var(--system-red);
  }

  .error {
    font-size: 0.8em;
  }
}

.input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.3em;
  border: 1px solid var(--separator);
  background-color: var(--system-background);
  color: var(--label);
  padding-inline: 0.5em;
  width: 100%;
  height: 2em;

  &:focus-within {
    border-color: var(--label);
  }

  &.invalid {
    border-color: var(--system-red);
    color: var(--system-red);
  }

  input {
    flex: 1;
    border: none;
    background: none;
    color: inherit;
    appearance: none;
    outline: none;

    &::placeholder {
      color: var(--tertiary-label);
    }
  }

  .password-visibility {
    flex: 0 0 1rem;
    cursor: pointer;
    top: 2.25em;
    right: 0.8em;
    height: 1rem;
    fill: var(--secondary-label);
    user-select: none;

    &:hover {
      fill: var(--label);
    }
  }
}
</style>
